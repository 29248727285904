/* Loading animation styles */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.bouncing-dots {
  display: flex;
  justify-content: space-between;
  width: 50px;
}

.bouncing-dots span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #3498db;
  animation: bed 0.6s infinite alternate;
}

.bouncing-dots span:nth-child(1) {
  animation-delay: 0s;
}

.bouncing-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bed {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Error page styles */
.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  background-color: #f2f2f2;
  color: #333;
}

.error-page h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.error-page p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.reload-btn {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.reload-btn:hover {
  background-color: #2980b9;
}
