/* Loader container */
#preloader {
  position: absolute;
  top: 0;
  left: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;

}

/* Spinner wrapper */
#status {
  display:flex;
  justify-content: center;
  align-items: center;
}

/* Spinner animation */
.spinner {
  position: relative;
  width: 40px;
  height: 40px;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #3880ff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  animation-delay: -1.0s;
}

/* Keyframes for bounce animation */
@keyframes bounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

/* Login Page Styles */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Ensures the container always covers the full screen height */
  background-color: #f4f4f4; /* Background color fallback */
  background-image: url('node.jpg');
  background-size: cover; /* Ensures the background image always covers the container */
  background-position: center; /* Centers the background image */
  background-repeat: no-repeat; /* Prevents repeating background */
  width: 100%; /* Ensures container takes full width */
  overflow: hidden; /* Prevents overflow of content */
  position: relative; /* Ensures fixed background positioning */
}

@media (max-width: 768px) {
  .login-container {
    height: auto; /* Adjusts height for smaller screens */
    padding: 20px; /* Adds padding to prevent image from cropping */
  }
}

@media (min-width: 1200px) {
  .login-container {
    min-height: 100vh; /* Ensures full height for large screens */
    padding: 0; /* Removes extra padding on larger screens */
  }
}




.logo{
	justify-content:center;
	align-items:center;
	display:flex;
}

.logo img {
  width: 100px; /* Adjust size as needed */
  margin-bottom: 20px; 
}

.login-form {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.input-field {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color:#f4f4f4;
  color: black;
}

.login-button {
  width: 100%;
  padding: 12px;
  background-color: #3880ff; /* Adjust to match SecureNode's primary color */
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: #2c6fe0; /* Slightly darker shade for hover effect */
}

.additional-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.forgot-password-link {
  color: #3880ff; /* Match with primary color */
  text-decoration: none;
  font-family:Calibri;
}

.forgot-password-link:hover {
  text-decoration: underline;
}
.remember-me{
	font-size:15px;
	font-family:Calibri;
	color: black;
}

.signup-and-copyright {
  margin-top: 20px;
  text-align: center;
  color:black;
}

.signup-link {
  font-size: 14px;
}

.signup-link a {
  color: #007bff;
}

.signup-link a:hover {
  text-decoration: underline;
}

.copyright {
  font-size: 12px;
  color: #aaa;
}
.error-message{
	color:red;
}


