/* General Styling */
body {
  margin: 0;
  font-family: "Arial", sans-serif;
}

.dashboard-content {
  padding: 15px;
  background-color: #f9f9f9;
}

/* Greeting Section */
.greeting-section {
  display: flex;
  justify-content: space-between;
  float: center;
  margin-bottom: 20px;
}

.greeting-section h3 {
  font-size: 20px;
  font-weight: ;
  margin: 0;
  font-family:monospace;
  padding-left:40px;
}

.follow-button {
  font-size: 14px;
}

/* Metric Cards */
.metric-card {
  text-align: center;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.metric-card h1 {
  font-size: 22px;
  color: #ef8e19 !important;
  margin: 5px 0;
  font-weight: bold;
}

.metric-card h2 {
  font-size: 22px;
  color: #68cf29 !important;
  margin: 5px 0;
  font-weight: bold;
}

.metric-card h5 {
  font-size: 22px;
  color: #ffc107 !important;
  margin: 5px 0;
  font-weight: bold;
}

.metric-card h4 {
  font-size: 22px;
  color: #dc3545 !important;
  margin: 5px 0;
  font-weight: bold;
}

.metric-card p {
  font-size: 14px;
  color: #6c757d;
}

.metric-card span {
  font-size: 14px;
  color: #6c757d;
}


/* Sidebar Styling */
.sidebar-content {
  background: #ffffff; /* Changed to white for clarity */
  color: #333333;
  height: 100%;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Added shadow for separation */
}

.sidebar-header h1 {
   /* Changed to white for clarity */
  color: white;
   /* Added shadow for separation */
}


.sidebar-header {
  text-align: center;
  padding: 20px;
  background: #007bff; /* Primary blue */
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  text-transform:;
}

.sidebar-header h5 {
  margin: 0;
  font-family:Cambria;
  font-size:15px;
  float:left;
  text-transform:;
}

.sidebar-content ion-item {
  --background: transparent;
  font-size: 16px;
  color: #333333;
  border-bottom: 1px solid #f1f1f1; /* Divider between items */
  margin: 0;
  padding: 10px 15px;
}

.sidebar-content ion-item:hover {
  background-color: #f8f9fa; /* Light hover effect */
}

.sidebar-content ion-item:active {
  background-color: #007bff; /* Active state with primary color */
  color: #ffffff;
}

.sidebar-content ion-icon {
  color: #007bff; /* Icon matches primary theme */
  font-size: 20px;
}

.sidebar-content ion-label {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
}

/* Sidebar Mobile Responsiveness */
@media (max-width: 768px) {
  .sidebar-content {
    width: 100%;
  }
  .sidebar-header {
    font-size: 18px;
    padding: 15px;
  }
  .sidebar-content ion-item {
    font-size: 14px;
    padding: 10px;
  }
}

/* Wallet Overview */
.wallet-overview {
  margin-top: 20px;
}

.wallet-overview h3 {
  margin-bottom: 15px;
  font-size: 20px;
  color: #333333;
  text-align : center;
}

.wallet-card {
  text-align: center;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.wallet-card ion-icon {
  font-size: 24px;
  color: #f39c12;
}

.wallet-card p {
  font-size: 14px;
  color: #6c757d;
  margin: 5px 0;
}

.wallet-card h4 {
  font-size: 18px;
  color: #1e88e5;
  margin: 10px 0;
}

/* TradingView Container */
.tradingview-container {
  margin-top: 20px;
  height: 400px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* CoinMarketCap Container */
.coinmarketcap-container {
  margin-top: 20px;
  text-align: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Responsive Design */
@media (max-width: 768px) {
  .greeting-section h2 {
    font-size: 20px;
	text-decoration:none;
  }

  .metric-card h3 {
    font-size: 18px;
  }

  .wallet-card h4 {
    font-size: 16px;
  }
}
.chike {
	font-family:Monotype Corsiva;
	display:block;
}
.chike h2 {
	font-size:25px;
	padding-left:25px;
}


.scroll-wrap {
  max-height: 400px;
  overflow-y: auto;
  margin-bottom:35px;
}
.marquee {
  animation: marquee 20s linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}


.dashboard-content {
  padding: 16px;
}

.dashboard-header h2 {
  margin: 0;
  padding: 16px 0;
  font-size: 24px;
  text-align: center;
}

.card {
  background: #fff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.card h3 {
  margin: 0 0 8px;
}

.wallet-section {
  background: #f9f9f9;
  padding: 16px;
  border-radius: 8px;
}

.transactions-section table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 16px;
}

.transactions-section th,
.transactions-section td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: center;
}

.transactions-section th {
  background: #f5f5f5;
}

.transactions-section tbody tr:hover {
  background: #f1f1f1;
}

.balance-row {
  margin: 10px 0;
}

#tradingview-widget {
  margin-top: 20px;
  width:100%;
  height:absolute;
  position:center;
  border-radius:10px;
}

.activate-bot {
  margin-top: 10px;
}

.order-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.coming-soon {
	text-align:center;
	font-family:Arial Black;
	font-size:25px;
	padding-top:200px;
}

.bottom-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.bottom-menu ion-router-link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.bottom-menu ion-icon {
  font-size: 20px;
  color: #333;
  transition: all 0.3s ease;
}

/* Add a stylish hover effect */
.bottom-menu ion-router-link:hover {
  background-color: #f0f0f0;
  transform: scale(1.1);
}

.bottom-menu ion-router-link:active {
  transform: scale(1.05);
}

/* Hover effect for icons */
.bottom-menu ion-router-link:hover ion-icon {
  color: #007bff; /* Change icon color on hover */
}

/* Adding a background circle to the icons */
.bottom-menu ion-router-link {
  background-color: #f8f8f8;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.bottom-menu ion-router-link:hover {
  background-color: #007bff;
}

.bottom-menu ion-router-link:active {
  background-color: #0056b3;
}

.coming-soon {
  animation: blink 1s infinite;
}


@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.recent-transactions-table table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  overflow-x: auto;
  display: block;
}

.recent-transactions-table th,
.recent-transactions-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.recent-transactions-table th {
  background-color: #f4f4f4;
}

.status-btn {
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.status-btn.pending {
  background-color: yellow;
  color: black;
  font-weight: bold;
}

.status-btn.completed {
  background-color: green;
  color: white;
}

.status-btn.failed {
  background-color: red;
  color: white;
}

.recent-transactions-table td {
  word-wrap: break-word;
  max-width: 200px;
}

/* src/global.css */


.coin-dropdown {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  width: 80px;
  transition: all 0.3s ease;
}

.coin-dropdown:focus {
  outline: none;
  border-color: #007bff;
}

.coin-dropdown option {
  padding: 10px;
}

.coin-dropdown:hover {
  cursor: pointer;
}

.coin-dropdown option[value="USD"] {
  background-color: #f1f1f1;
}

.coin-dropdown option[value="BTC"] {
  background-color: #ffcc00;
}

.coin-dropdown option[value="ETH"] {
  background-color: #4e73df;
  color: white;
}

.coin-dropdown option[value="LTC"] {
  background-color: #90a4ae;
}

.chat-support-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Bouncing animation for the hand pointer */
@keyframes blend {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.hand-pointer {
  font-size: 2rem;
  position: absolute;
  bottom: -30px;  /* Adjust as needed */
  right: 20px;
  animation: blend 1.5s infinite;
  visibility: visible;  /* Ensure it's visible */
}

