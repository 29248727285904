/* Container for the marquee */
.marquee-container {
  width: 100%;
  overflow: hidden;
  background-color: #333;
  color: white;
  padding: 10px 0;
  margin: 0 auto;
}

/* The animated content that scrolls */
.marquee-content {
  display: flex;
  animation: scroll 20s linear infinite; /* Scroll speed */
  white-space: nowrap; /* Prevent the content from wrapping */
}

/* Individual coin item in the marquee */
.marquee-item {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #444;
  border-radius: 15px;
  font-size: 16px;
  font-family: 'Arial', sans-serif;
  text-transform: capitalize;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

/* Hover effect for coin items */
.marquee-item:hover {
  transform: scale(1.05);
  background-color: #555;
}

/* Keyframes for the scrolling animation */
@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
