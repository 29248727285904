.forgot-password-card {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.forgot-password-card h2 {
  font-size: 24px;
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

#p {
  font-size: 15px;
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}


.forgot-password-card .back-to-login {
  margin-top: 20px;
  text-align: center;
}

.forgot-password-card .back-to-login IonButton {
  margin-top: 10px;
}

.custom-alert .alert-wrapper {
  background-color: #f4f7fa; /* Light background color */
  border-radius: 12px; /* Rounded corners */
  padding: 20px;
}

.custom-alert .alert-header {
  background-color: #4CAF50; /* Green background for success */
  color: white;
  border-radius: 8px 8px 0 0; /* Rounded top corners */
}

.custom-alert .alert-button {
  background-color: #4CAF50; /* Green button */
  color: white;
}

.custom-alert .alert-button:last-child {
  background-color: #f44336; /* Red button for error */
  color: white;
}

/* Custom styling for the Forgot Password page */
.forgot-password-card {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  margin: 20px;
}

.forgot-password-card h2 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  text-align: center;
}

.subheading {
  text-align: center;
  font-size: 1rem;
  color: #666;
  margin-top: 10px;
}

.ion-item {
  margin-bottom: 20px;
}

.ion-input {
  border-radius: 4px;
  background-color: #f9f9f9;
}

.back-to-login {
  margin-top: 20px;
  text-align: center;
}

.custom-alert {
  background-color: #fff;
  color: #333;
}

button {
  border-radius: 5px;
}

button:focus {
  outline: none;
}

.ion-button {
  margin-top: 20px;
}
