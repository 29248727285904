/* For information on how to create your own theme, please see:
http://ionicframework.com/docs/theming/ */
:root {
  --ion-background-color: #ffffff;
  --ion-text-color: #000000;
}

.dark {
  --ion-background-color: #000000;
  --ion-text-color: #ffffff;
}
